import { useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  connect as connectTwilio,
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  LocalVideoTrackPublication,
  Participant,
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteVideoTrack,
  Room,
  TwilioError,
} from "twilio-video";
import { useAVDevices } from "./useAVDevices";

export const useVideoCall = ({
  selectedAudioOutputDeviceId: _selectedAudioOutputDeviceId,
  selectedAudioInputDeviceId,
  selectedVideoInputDeviceId,
  facingMode,
  addToLog,
  isVideoInputDisabled,
  isAudioInputDisabled,
  screenStream,
}: {
  selectedAudioOutputDeviceId: string | null;
  selectedAudioInputDeviceId: string | null;
  selectedVideoInputDeviceId: string | null;
  addToLog: (text: string) => void;
  isAudioInputDisabled: boolean;
  isVideoInputDisabled: boolean;
  screenStream: MediaStream | undefined;
  facingMode: null | "user" | "environment";
}) => {
  const [state, setState] = useState<
    "idle" | "connecting" | "error" | "connected"
  >("idle");

  const { askPermission } = useAVDevices();

  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const [isVideoInitialized, setIsVideoInitialized] = useState(false);

  const isReady = isAudioInitialized && isVideoInitialized;

  const [localVideoTrack, setLocalVideoTrack] = useState<
    LocalVideoTrack | undefined
  >(undefined);

  const [localAudioTrack, setLocalAudioTrack] = useState<
    LocalAudioTrack | undefined
  >(undefined);

  const [remoteVideoTracks, setRemoteVideoTracks] = useState<
    { track: RemoteVideoTrack; participant: RemoteParticipant }[]
  >([]);

  const [remoteAudioTracks, setRemoteAudioTracks] = useState<
    { track: RemoteAudioTrack; participant: RemoteParticipant }[]
  >([]);

  const [mutedParticipants, setMutedParticipants] = useState<
    RemoteParticipant[]
  >([]);

  const [isLoadingMobileCamera, setIsLoadingMobileCamera] = useState(false);

  const roomReference = useRef<Room>();

  const room = roomReference.current;

  const handleNewLocalTrack = useCallback(
    async (newTrack: LocalTrack) =>
      new Promise<LocalTrackPublication>((resolve, reject) => {
        addToLog(`Created local ${newTrack.kind} device`);
        newTrack.on("started", async (track) => {
          addToLog(`Started local ${track.kind} device`);
          if (room) {
            if (localAudioTrack && track.kind === "audio") {
              // just to make sure the track is unpublished
              // unpublishing of audio track is done in initAudio useEffect
              room.localParticipant.unpublishTracks([localAudioTrack]);
            }
            if (localVideoTrack && track.kind === "audio") {
              // just to make sure the track is unpublished
              // unpublishing of audio track is done in initAudio useEffect
              room.localParticipant.unpublishTracks([localVideoTrack]);
            }
            addToLog(`Published local ${track.kind} track`);
            const trackPublication = await room.localParticipant.publishTrack(
              track
            );
            resolve(trackPublication);
          } else {
            reject(`Unable to published local ${track.kind} track`);
          }
        });
      }),
    [addToLog, room]
  );

  /**
   * LOCAL VIDEO STUFF
   */

  useEffect(() => {
    const initVideo = async () => {
      if (
        !selectedVideoInputDeviceId ||
        (isMobile && !facingMode && !selectedVideoInputDeviceId)
      ) {
        setIsVideoInitialized(true);
        return;
      }

      let isSuccess = false;
      let newVideoTrack = localVideoTrack;
      let numberOfRetries = 0;

      do {
        try {
          setIsLoadingMobileCamera(true);
          await new Promise((r) => {
            setTimeout(r, 751);
          });
          const isEnvironmentCamera = isMobile && facingMode === "environment";
          const localVideoTrackOptions = isEnvironmentCamera
            ? { facingMode: "environment" }
            : { deviceId: selectedVideoInputDeviceId };

          await askPermission({ facingMode });
          newVideoTrack?.stop();
          newVideoTrack = await createLocalVideoTrack(localVideoTrackOptions);
          setIsLoadingMobileCamera(false);
          setIsVideoInitialized(true);
          isSuccess = true;
        } catch (e) {
          if (numberOfRetries > 20) {
            setIsLoadingMobileCamera(false);
            setIsVideoInitialized(true);
            isSuccess = true;
            alert("An error occurred");
          }
          numberOfRetries += 1;
          console.log(e);
        }
      } while (!isSuccess);
      if (isVideoInputDisabled) {
        newVideoTrack?.disable();
      }
      setLocalVideoTrack(newVideoTrack);
      setIsVideoInitialized(true);
      setIsLoadingMobileCamera(false);

      return handleNewLocalTrack(
        newVideoTrack as LocalTrack
      ) as Promise<LocalVideoTrackPublication>;
    };
    /*
      When user shares screen we need to remove current video input device stream, 
      and add it back after the screen sharing ends
    */
    const newVideoTrack = !screenStream ? initVideo() : Promise.resolve();
    return () => {
      newVideoTrack.then((track) => {
        track?.track.stop();
        track?.unpublish();
      });
    };
  }, [
    handleNewLocalTrack,
    selectedVideoInputDeviceId,
    screenStream,
    facingMode,
  ]);

  useEffect(() => {
    if (!localVideoTrack) return;
    if (isVideoInputDisabled) {
      localVideoTrack.disable();
    } else {
      localVideoTrack.enable();
    }
  }, [isVideoInputDisabled]);

  /**
   * LOCAL AUDIO STUFF
   */

  useEffect(() => {
    const initAudio = async () => {
      if (!selectedAudioInputDeviceId) {
        setIsAudioInitialized(true);
        return;
      }
      const newAudioTrack = await createLocalAudioTrack({
        deviceId: selectedAudioInputDeviceId,
      });
      if (isAudioInputDisabled) {
        newAudioTrack.disable();
      }
      setLocalAudioTrack(newAudioTrack);
      setIsAudioInitialized(true);
      return handleNewLocalTrack(newAudioTrack);
    };

    const at = initAudio();

    return () => {
      at.then((at) => {
        at?.unpublish();
      });
    };
  }, [handleNewLocalTrack, selectedAudioInputDeviceId]);

  useEffect(() => {
    if (!localAudioTrack) return;
    if (isAudioInputDisabled) {
      localAudioTrack.disable();
    } else {
      localAudioTrack.enable();
    }
  }, [isAudioInputDisabled]);

  /**
   * REMOTE PARTICIPANTS STUFF
   */

  const addVideoTrack = ({
    track,
    participant,
  }: {
    track: RemoteVideoTrack;
    participant: RemoteParticipant;
  }) => {
    setRemoteVideoTracks((tracks) => [
      ...tracks,
      { track: track, participant },
    ]);
  };

  const removeVideoTrack = ({ track }: { track: RemoteVideoTrack }) => {
    setRemoteVideoTracks((tracks) =>
      tracks.filter((currentTrack) => currentTrack.track.sid !== track.sid)
    );
  };

  const addAudioTrack = ({
    track,
    participant,
  }: {
    track: RemoteAudioTrack;
    participant: RemoteParticipant;
  }) => {
    setRemoteAudioTracks((tracks) => [
      ...tracks,
      { track: track, participant },
    ]);
  };

  const addMutedParticipant = (participant: RemoteParticipant) => {
    setMutedParticipants((mutedParticipans) => [
      ...mutedParticipans,
      participant,
    ]);
  };
  const removeMutedParticipant = (participant: RemoteParticipant) => {
    setMutedParticipants((mutedParticipans) =>
      mutedParticipans.filter(
        (currentParticipant) => currentParticipant.sid !== participant.sid
      )
    );
  };

  const removeAudioTrack = ({ track }: { track: RemoteAudioTrack }) => {
    setRemoteAudioTracks((tracks) =>
      tracks.filter((currentTrack) => currentTrack.track.sid !== track.sid)
    );
  };

  const handleNewParticipant = useCallback(
    (participant: RemoteParticipant) => {
      // Show tracks of participant

      participant.tracks.forEach((publication) => {
        addToLog(
          `User ${participant.identity} has track ${publication.trackName}`
        );

        publication.on("subscribed", (track) => {
          if (track.kind === "video") {
            addVideoTrack({ track, participant });
          } else if (track.kind === "audio") {
            addAudioTrack({ track, participant });
            if (!track.isEnabled) {
              addMutedParticipant(participant);
            }
          }
          addToLog(
            `Subscribed to ${participant.identity}s  *${track.kind}* track ${track.name}`
          );

          publication.track?.on("disabled", (tr) => {
            addToLog(
              `User ${participant.identity} disabled his *${tr.kind}* track`
            );
            if (tr.kind === "audio") {
              addMutedParticipant(participant);
            }
          });

          publication.track?.on("enabled", (tr) => {
            addToLog(
              `User ${participant.identity} enabled his *${tr.kind}* track`
            );
            if (tr.kind === "audio") {
              removeMutedParticipant(participant);
            }
          });
        });

        publication.on("unsubscribed", (track) => {
          if (track.kind === "video") {
            removeVideoTrack({ track });
          } else if (track.kind === "audio") {
            removeAudioTrack({ track });
          }
          addToLog(
            `Unsubscribed to ${participant.identity}s  *${track.kind}* track ${track.name}`
          );
        });
      });

      // REMOTE PARTICIPANT TRACK SUBSCRIBED
      participant.on("trackPublished", (publication) => {
        publication.on("subscribed", (track) => {
          if (track.kind === "video") {
            addVideoTrack({ track, participant });
          } else if (track.kind === "audio") {
            addAudioTrack({ track, participant });
            if (!track.isEnabled) {
              addMutedParticipant(participant);
            }
          }
          addToLog(
            `Subscribed to ${participant.identity}s  *${track.kind}* track ${track.name}`
          );

          publication.track?.on("disabled", (tr) => {
            addToLog(
              `User ${participant.identity} disabled his *${tr.kind}* track`
            );
            if (tr.kind === "audio") {
              addMutedParticipant(participant);
            }
          });

          publication.track?.on("enabled", (tr) => {
            addToLog(
              `User ${participant.identity} enabled his *${tr.kind}* track`
            );
            if (tr.kind === "audio") {
              removeMutedParticipant(participant);
            }
          });
        });
        publication.on("unsubscribed", (track) => {
          if (track.kind === "video") {
            removeVideoTrack({ track });
          } else if (track.kind === "audio") {
            removeAudioTrack({ track });
          }
          addToLog(
            `Unsubscribed to ${participant.identity}s  *${track.kind}* track ${track.name}`
          );
        });
      });

      // REMOTE PARTICIPANT TRACK UNPUBLISHED
      participant.on("trackUnsubscribed", (track) => {
        addToLog(
          `User ${participant.identity} unpublished track ${track.name}`
        );
      });
    },
    [addToLog]
  );

  const handleParticipantLeave = (_participant: Participant) => {};

  const handleConnectedToRoom = useCallback(
    (newRoom: Room) => {
      addToLog("Connected to the room");

      // // handle existing participants
      newRoom.participants.forEach((participant) => {
        addToLog(`User ${participant.identity} already in the room`);
        handleNewParticipant(participant);
      });

      // When new participant is connected
      newRoom.on("participantConnected", (participant) => {
        addToLog(`A remote participant connected: ${participant.identity}`);
        handleNewParticipant(participant);
      });

      newRoom.once("participantDisconnected", (participant) => {
        addToLog(
          `Participant "${participant.identity}" has disconnected from the Room`
        );
        handleParticipantLeave(participant);
      });

      roomReference.current = newRoom;
    },
    [addToLog]
  );

  // CONNECTING TO ROOM STUFF

  const connect = useCallback(
    async function connect({
      roomName,
      accessToken,
    }: {
      roomName: string;
      accessToken: string;
    }) {
      if (!accessToken) {
        console.error("Called connect without access token");
        return;
      }
      if (!roomName) {
        console.error("Called connect without room name");
        return;
      }
      const localTracks: LocalTrack[] = [];
      if (!!localAudioTrack && isAudioInitialized) {
        localTracks.push(localAudioTrack);
      }
      if (!!localVideoTrack && isVideoInitialized) {
        localTracks.push(localVideoTrack);
      }

      try {
        setState("connecting");
        const newRoom = await connectTwilio(accessToken, {
          name: roomName,
          tracks: localTracks,
          region: "us1",
          networkQuality: {
            local: 2,
            remote: 2,
          },
        });
        handleConnectedToRoom(newRoom);
        setState("connected");
        return newRoom;
      } catch (e) {
        setState("error");
        addToLog((e as TwilioError).message);
        console.log(e);
      }
    },
    [addToLog, handleConnectedToRoom, localAudioTrack, localVideoTrack]
  );

  const disconnect = () => {
    room?.disconnect();
  };

  const twilioRoomState = room?.state;

  // SCREEN SHARING STUFF

  useEffect(() => {
    const init = async () => {
      let newScreenStream = null;

      if (screenStream) {
        newScreenStream = await room?.localParticipant.publishTrack(
          screenStream.getVideoTracks()[0]
        );
      }
      return newScreenStream;
    };

    const newScreenStream = init();

    return () => {
      newScreenStream.then((resolvedNewScreenStream) => {
        if (resolvedNewScreenStream) {
          room?.localParticipant.unpublishTrack(resolvedNewScreenStream.track);
        }
      });
    };
  }, [screenStream]);

  return {
    roomReference: room,
    localParticipant: {
      localParticipant: room?.localParticipant,
      videoTrack: localVideoTrack,
      audioTrack: localAudioTrack,
      videoTrackDisabled: isVideoInputDisabled,
      audioTrackDisabled: isAudioInputDisabled,
    },
    remoteParticipants: room?.participants,
    remoteVideoTracks,
    remoteAudioTracks,
    mutedParticipants,
    connect,
    disconnect,
    state,
    twilioRoomState,
    isReady,
    isLoadingMobileCamera,
  };
};
